@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap);
* {
  box-sizing: border-box;
}

html::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #f2f4f8;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  display: block;
  color: #000000;
}

button {
  outline: none;
  border: none;
}

.input,
.passwordInput,
.emailInput,
.nameInput,
.textarea {
  box-shadow: rgba(0, 0, 0, 0.11);
  border: none;
  background: #ffffff;
  border-radius: 3rem;
  height: 3rem;
  width: 100%;
  outline: none;
  font-family: 'Montserrat', sans-serif;
  padding: 0 3rem;
  font-size: 1rem;
}
@media (min-width: 1100px) {
  .input,
  .passwordInput,
  .emailInput,
  .nameInput,
  .textarea {
    padding: 0 5rem;
  }
}

.textarea {
  padding: 1rem 1.5rem;
  height: 300px;
  border-radius: 1rem;
}

.primaryButton {
  cursor: pointer;
  background: #00cc66;
  border-radius: 1rem;
  padding: 0.85rem 2rem;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.25rem;
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.removeIcon {
  cursor: pointer;
  position: absolute;
  top: -3%;
  right: -2%;
}

.editIcon {
  cursor: pointer;
  position: absolute;
  top: -3.4%;
  right: 20px;
}

.pageContainer,
.questions,
.profile,
.listingDetails,
.category,
.explore {
  margin: 1rem;
}
@media (min-width: 1024px) {
  .pageContainer,
  .questions,
  .profile,
  .listingDetails,
  .category,
  .explore {
    margin: 3rem;
  }
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageContainer{
  margin-bottom: 10rem;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #00cc66 transparent #00cc66 transparent;
  border-radius: 50%;
  -webkit-animation: spin 1.2s linear infinite;
          animation: spin 1.2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.pageHeader {
  font-size: 2rem;
  text-align: center;
  font-weight: 800;
}

.navbar {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 85px;
  background-color: #ffffff;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarNav {
  width: 100%;
  margin-top: 0.75rem;
  overflow-y: hidden;
}

.navbarListItems {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navbarListItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbarListItemName,
.navbarListItemNameActive {
  margin-top: 0.25rem;
  font-size: 14px;
  font-weight: 600;
  color: #8f8f8f;
}
.navbarListItemNameActive {
  color: #2c2c2c;
}

.nameInput {
  margin-bottom: 2rem;
  background: url(/static/media/badgeIcon.6a60b5bc.svg) #ffffff 2.5% center no-repeat;
}

.emailInput {
  margin-bottom: 2rem;
  background: url(/static/media/personIcon.60c17ce3.svg) #ffffff 2.5% center no-repeat;
}

.passwordInputDiv {
  position: relative;
}

.passwordInput {
  margin-bottom: 2rem;
  background: url(/static/media/lockIcon.a38a1b56.svg) #ffffff 2.5% center no-repeat;
}

.showPassword {
  cursor: pointer;
  position: absolute;
  top: -4%;
  right: 1%;
  padding: 1rem;
}

.forgotPasswordLink {
  cursor: pointer;
  color: #00cc66;
  font-weight: 600;
  text-align: right;
}

.signInBar,
.signUpBar {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: inherit;
}

.signInButton,
.signUpButton,
.signInText,
.signUpText {
  cursor: pointer;
}
@media (min-width: 1024px) {
  .signInBar,
  .signUpBar {
    justify-content: start;
  }
}

.signInText,
.signUpText {
  font-size: 1.5rem;
  font-weight: 700;
}

.signInButton,
.signUpButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  background-color: #00cc66;
  border-radius: 50%;
}
@media (min-width: 1024px) {
  .signInButton,
  .signUpButton {
    margin-left: 3rem;
  }
}

.socialLogin {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialIconDiv {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  margin: 1.5rem;
  width: 3rem;
  height: 3rem;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.socialIconImg {
  width: 100%;
}

.registerLink {
  margin-top: 4rem;
  color: #00cc66;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3rem;
}

@media (min-width: 1217px) {
  .explore {
    margin-bottom: 10rem;
  }
}
@media (max-height: 536px) {
  .explore {
    margin-bottom: 10rem;
  }
}

.exploreHeading,
.exploreCategoryHeading {
  font-weight: 700;
}

.exploreCategoryHeading {
  margin-top: 3rem;
}

.swiper-container {
  min-height: 225px;
  height: 23vw;
}

.swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}


.swiperSlideDiv {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiperSlideImg {
  width: 100%;
  object-fit: cover;
}

.swiperSlideText {
  color: #ffffff;
  position: absolute;
  top: 70px;
  left: 0;
  font-weight: 600;
  max-width: 90%;
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5rem;
}
@media (min-width: 1024px) {
  .swiperSlideText {
    font-size: 1.75rem;
  }
}

.swiperSlidePrice {
  color: #000000;
  position: absolute;
  top: 143px;
  left: 11px;
  font-weight: 600;
  max-width: 90%;
  background-color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
}
@media (min-width: 1024px) {
  .swiperSlidePrice {
    font-size: 1.25rem;
  }
}

.exploreCategories {
  display: flex;
  justify-content: space-between;
}
.exploreCategories a {
  width: 48%;
}

.exploreCategoryImg {
  min-height: 115px;
  height: 15vw;
  width: 100%;
  border-radius: 1.5rem;
  object-fit: cover;
  margin: 0 auto;
}

.exploreCategoryName {
  font-weight: 500;
  text-align: left;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin-bottom: 80px;
  /* Your existing styles for the category container */
}

.category header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f1f1f1;
}

.categoryListings {
padding: 0;
  
}

.categoryListing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  width:100%;

}

.questionItemStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #000000;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  position: relative;
}

.categoryListingLink {
  display: contents;
}

.categoryListingImg {
  width: 30%;
  height: 100px;
  border-radius: 1.5rem;
  object-fit: cover;
}
@media (min-width: 1024px) {
  .categoryListingImg {
    width: 19%;
    height: 217px;
  }
}

.categoryListingDetails {
  align-items: center;
  text-align: center;
  width: 90%;
}
@media (min-width: 1024px) {
  .categoryListingDetails {
    width: 79%;
  }
}

.categoryListingLocation {
  font-weight: 600;
  font-size: 0.7rem;
  opacity: 0.8;
  margin-bottom: 0;
}
/* Add these styles for the clickable options */
.optionsContainer {
  display: flex;
  margin-top: 1rem;
}

.optionBox {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 0.5rem;
  padding: 0.6rem; /* Adjust padding to suit your design */
  text-align: center;
  font-weight: 600;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  margin-right: 5rem;
  margin-bottom: 1rem; 

  /* Define a fixed height for the box */
  height: 50px;
  width:80%; /* Adjust to your preferred height */

  display: flex;
  justify-content: center;
  align-items: center;
}
.optionBox:active {
  background-color: #7b68ee; /* Change to the desired color when the container is clicked */
}

.optionBox.selected {
  background-color: #7b68ee; /* Change this color to purple */
}

.sliderContainer {
  display: flex;
  align-items: center;
  width:100%,
}

.sliderContainer .categoryListingInfoText:first-child {
  margin-right: 20px; /* Adjust the margin as needed to control the spacing between the text and the slider */
}

.sliderContainer input[type="range"] {
  width: 180px; 
  
}
/* CSS Styles */
.openedQuestionOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  /* justify-content: center;
  align-items: center; */
  z-index: 9999; /* Ensure it's above everything else on the page */
}

.openedQuestionBlock {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 80px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  height: 50%;
  text-align: center;
  z-index: 9999; /* Set a high z-index to make it appear above everything */
}

.openedQuestionBlock .closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: red;
}
.plus-icon {
  position: fixed;
  bottom: 100px; /* Adjust this value to control the vertical position */
  right: 20px; /* Adjust this value to control the horizontal position */
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  background-color: blue; /* Set the background color to blue */
  color: white; /* Set the text color to white */
  border-radius: 50%; /* Make it a circle */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 50px; /* Vertically center the plus sign */
  font-size: 24px; /* Adjust the font size as needed */
  cursor: pointer; /* Add a pointer cursor to indicate it's clickable */
  /* Add any other styles you want, e.g., box-shadow, transition effects, etc. */
}


.new-question-modal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  text-align: center;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's above other content */
}
.block-info {
  position: absolute;
  top: 5%; /* Adjust to control vertical position */
  left: 10%; /* Adjust to control horizontal position */
  width: 80%; /* Adjust to control the width */
  height: 80%; /* Adjust to control the height */
  background: #fff; /* Background color for the modal */
  z-index: 1001; /* Ensure it's above the modal overlay (1000) */
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 7px;
  cursor: pointer;
  font-size: 15px;
  color: #333;
}

.click-list {
  margin-top: 20px;
  flex-grow: 1; /* Allow the click list to fill the available space */
}
.edit-button {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 7px;
  cursor: pointer;
  color: #333;
  z-index: 1; /* Ensure it's above other content */
}

.edit-button-container {
  margin-top: 20px;
}
.edit-input {
  width: 20%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.delete-button {
  background: #e74c3c; /* Softer red color */
  color: white;
  font-size: 12px; /* Slightly smaller font size */
  padding: 8px 16px; /* Slightly smaller padding */
  border: none;
  width:40%;
  align-self: center;
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px; /* Added border radius for a rounded look */
  transition: background-color 0.2s; /* Smooth color transition */
}

.delete-button:hover {
  background: #c0392b; /* Darker red color on hover */
}

.delete-button:active {
  background: #922b21; /* Even darker red color when clicked */
}


.close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

input[type="text"] {
  border-radius: 5px;
  border-color: 'white';
}

.question-input {
  font-size: 24px;
  display: block;
  margin: 0 auto;
}

.option-inputs {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}
.categories {
  margin-bottom: 20px;
}

.category-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 10px;
}

.category-item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 120px;
  padding: 10px;
  margin-right: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  cursor: pointer;
  scroll-snap-align: start;
  transition: background-color 0.2s ease;
  text-decoration: none;
  color: #333;
}

.block-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distribute blocks evenly */
  grid-gap: 20px;
  gap: 20px; /* Add space between blocks */
}

.block {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(50% - 20px);
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: var(--blockColor);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-animation: activeEffect 0.8s ease-in-out;
          animation: activeEffect 0.8s ease-in-out; /* Use animation instead of transition */
}
.block:active {
  background-color: darken(var(--blockColor), 10%); /* Darken the background color by 10% when active */
}


/* Define a keyframes animation for the :active state */

/* You can adjust the animation duration and properties as needed */

/* Add more styles as needed */





.expanded {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; /* Ensures it's on top of other content */
  background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}


.newblock {
  display:flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(50% - 20px); /* Set flex-basis for two blocks per row with spacing */
  height: auto; /* Allow the height to adjust based on content */
  margin-bottom: 20px; /* Adjust margin to control vertical spacing */
  border-radius: 10px;
  background-color: var(--blockColor);
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;

}
.category-item:hover {
  background-color: #ddd;
}

.option-input {
  flex: 1 1;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  line-height: 2;
  margin: 5px;
}
.groups {
  margin-bottom: 20px;
}

.group-scroll {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 10px;
}

.group-item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 150px;
  padding: 10px;
  margin-right: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  cursor: pointer;
  scroll-snap-align: start;
  transition: background-color 0.2s ease;
  text-decoration: none;
  color: #333;
}

.group-item:hover {
  background-color: #ddd;
}

.new-group {
  background-color: #009688;
  color: #fff;
  cursor: pointer;
}

.plus-icon {
  font-size: 24px;
  margin-bottom: 5px;
}
.sendContainer {
  border: 1px solid #ccc; /* Border around the send button and text */
  padding: 5px; /* Add padding for spacing */
  display: flex;
  width:70%;
  border-radius: 2rem;
  background-color: lightblue;
  justify-content: center;
  align-items: center;
  margin-top: 10px; /* Adjust margin as needed */
}
.sendContainer:active {
  background-color: #b3e0ff; /* Change to the desired color when the container is clicked */
}
.sendButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #007bff;
  transition: color 0.3s;
  margin-right: 10px; /* Adjust margin as needed */
}
.tabs {
  display: flex;
  width: 100%; /* Tabs fill the screen lengthwise */
  justify-content: space-around; /* Tabs are adjacent to each other */
  margin-top: -20px; /* Add margin to push the tabs closer to the header */
}

.activeTab {
  padding: 0px 30px;
  border: none;
  cursor: pointer;
  /* You can add styles for active tabs if needed */
}

.inactiveTab {
  padding: 0px 30px;
  border: none;
  cursor: pointer;
  /* You can add styles for inactive tabs if needed */
}

.activeTab:hover {
  /* Add hover styles if needed */
}




/* Additional styling for input fields, buttons, etc. */


.categoryListingName {
  text-align: center;
  align-self: center;
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;
}

.categoryListingPrice {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 1.1rem;
  color: #00cc66;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.categoryListingInfoDiv {
  display: flex;
  justify-content: space-between;
  width:100%,

}

.categoryListingInfoText {
  font-weight: 500;
  font-size: 0.8rem;
  white-space: nowrap;
}
input[type="range"] {
  width: 100%; /* Adjust this value to make it longer */
  height: 20px; /* Adjust this value to make it taller */
  /* Add other styles as needed */
}

.loadMore {
  cursor: pointer;
  width: 8rem;
  margin: 0 auto;
  text-align: center;
  padding: 0.25rem 0.5rem;
  background-color: #000000;
  color: #ffffff;
  font-weight: 600;
  border-radius: 1rem;
  opacity: 0.7;
  margin-top: 2rem;
}

.listingDetails {
  margin-bottom: 10rem;
}

.shareIconDiv {
  cursor: pointer;
  position: fixed;
  top: 3%;
  right: 5%;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listingName {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.listingLocation {
  margin-top: 0;
  font-weight: 600;
}

.discountPrice {
  padding: 0.25rem 0.5rem;
  background-color: #000000;
  color: #ffffff;
  border-radius: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  display: inline;
}

.listingType {
  padding: 0.25rem 0.5rem;
  background-color: #00cc66;
  color: #ffffff;
  border-radius: 2rem;
  display: inline;
  font-weight: 600;
  font-size: 0.8rem;
  margin-right: 1rem;
}
.centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* For text alignment if needed */
  /* Add any other necessary styles */
}




.listingDetailsList {
  padding: 0;
  list-style-type: none;
}
.listingDetailsList li {
  margin: 0.3rem 0;
  font-weight: 500;
  opacity: 0.8;
}

.listingLocationTitle {
  margin-top: 2rem;
  font-weight: 600;
  font-size: 1.25rem;
}

.leafletContainer {
  width: 100%;
  height: 200px;
  overflow-x: hidden;
  margin-bottom: 3rem;
}
@media (min-width: 1024px) {
  .leafletContainer {
    height: 400px;
  }
}

.linkCopied {
  position: fixed;
  top: 9%;
  right: 5%;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
}

.contactListingName {
  margin-top: -1rem;
  margin-bottom: 0;
  font-weight: 600;
}

.contactListingLocation {
  margin-top: 0.25rem;
  font-weight: 600;
}

.contactLandlord {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

.landlordName {
  font-weight: 600;
  font-size: 1.2rem;
}

.messageForm {
  margin-top: 0.5rem;
}

.messageDiv {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}

.messageLabel {
  margin-bottom: 0.5rem;
}

.profile {
  margin-bottom: 10rem;
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logOut {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #00cc66;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
}

.profileDetailsHeader {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
}

.personalDetailsText {
  font-weight: 600;
}

.changePersonalDetails {
  cursor: pointer;
  font-weight: 600;
  color: #00cc66;
}

.profileCard {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.2);
  max-width: 500px;
}

.profileDetails {
  display: flex;
  flex-direction: column;
}

.profileName,
.profileEmail,
.profileAddress,
.profileAddressActive,
.profileEmailActive,
.profileNameActive {
  all: unset;
  margin: 0.3rem 0;
  font-weight: 600;
  width: 100%;
}
.profileNameActive {
  background-color: rgba(44, 44, 44, 0.1);
}

.profileEmail,
.profileAddress,
.profileAddressActive,
.profileEmailActive {
  font-weight: 500;
}
.profileEmailActive {
  background-color: rgba(44, 44, 44, 0.1);
}

.profileAddressActive {
  background-color: rgba(44, 44, 44, 0.1);
}

.createListing {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.25rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  font-weight: 600;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listingText {
  margin-top: 3rem;
  font-weight: 600;
}

.lisitingsList {
  padding: 0;
}

.formLabel {
  font-weight: 600;
  margin-top: 1rem;
  display: block;
}

.formButtons {
  display: flex;
}

.formButton,
.formInput,
.formInputAddress,
.formInputName,
.formInputSmall,
.formInputFile,
.formButtonActive {
  padding: 0.9rem 3rem;
  background-color: #ffffff;
  font-weight: 600;
  border-radius: 1rem;
  font-size: 1rem;
  margin: 0.5rem 0.5rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formButtonActive {
  background-color: #00cc66;
  color: #ffffff;
}

.flex {
  display: flex;
}

.formInput,
.formInputAddress,
.formInputName,
.formInputSmall,
.formInputFile {
  border: none;
  outline: none;
  font-family: 'Montserrat', sans-serif;
}
.formInputSmall,
.formInputFile {
  margin-right: 3rem;
  padding: 0.9rem 0.7rem;
  text-align: center;
}

.formInputName {
  padding: 0.9rem 1rem;
  width: 90%;
  max-width: 326px;
}

.formInputAddress {
  padding: 0.9rem 1rem;
  width: 90%;
  max-width: 326px;
}

.formPriceDiv {
  display: flex;
  align-items: center;
}

.formPriceText {
  margin-left: -1.5rem;
  font-weight: 600;
}

.imagesInfo {
  font-size: 0.9rem;
  opacity: 0.75;
}

.formInputFile {
  width: 100%;
}
.formInputFile::-webkit-file-upload-button {
  background-color: #00cc66;
  border: none;
  color: #ffffff;
  font-weight: 600;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  margin-right: 1rem;
}

.createListingButton {
  margin-top: 5rem;
}

.questions {
  margin-bottom: 10rem;
}

.questionBadge {
  padding: 0.25rem 0.5rem;
  background-color: #000000;
  color: #ffffff;
  border-radius: 1rem;
  margin-left: 1rem;
  font-size: 0.8rem;
  opacity: 0.75;
}

